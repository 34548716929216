import React, { useCallback, useEffect, useState } from 'react'
// @ts-ignore
// import TimeMe from 'timeme.js'
import {
  Box,
  CircularProgress,
  Grid,
  ThemeProvider,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  LibraryThemeProvider,
  flockTheme,
  TrackedFooter,
} from '@flock/shared-ui'
import { localStore } from '@flock/utils'
import { useQuery } from '@apollo/client'
import {
  Core_SalesforceAccount,
  LandingGetSalesforceAccountDocument,
  Core_SalesforceScenario,
} from '@flock/flock-gql-server/src/__generated__/graphql'

import OfferPageWrapper from '../../components/SalesforceOfferPageComponents/OfferPageWrapper'
import { useRecordPageDuration } from '../../components/utils'
import { identify, shouldTrack } from '../../utils/analytics'
import SectionLayout from '../../components/SharedComponents/SectionLayout'

import { SalesforceOfferPageData } from '../../components/SalesforceOfferPageComponents/offerPageTypes'
import {
  OfferPageContextProvider,
  OfferPageContextType,
} from '../../components/OfferPageComponents/OfferPageContext'
import OfferPage from '../../components/SalesforceOfferPageComponents/OfferPage'
import ErrorCard from '../../components/SalesforceOfferPageComponents/ErrorCard'
import {
  DEFAULT_SALES_PHONE_NUMBER,
  DEFAULT_SALES_EMAIL,
  DEFAULT_SALES_CALENDLY,
} from '../../constants'

export const Head = () => (
  <>
    <meta name="robots" content="noindex" />
  </>
)

type PropertyEstimateProps = {
  params: {
    account: string
  }
}

const OfferPageV2 = (props: PropertyEstimateProps) => {
  const { params } = props
  const { account: accountId } = params
  useRecordPageDuration()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  // TODO: Implement accountId validation
  const invalidUuid = typeof window !== 'undefined' && false // && !isAccountId(accountId)
  const [loading, setLoading] = useState<boolean>(true)
  const [selectedScenario, setSelectedScenario] = useState<number>(0)

  const [pageData, setPageData] = useState<Partial<SalesforceOfferPageData>>({})
  const [error, setError] = useState(false)

  const { refetch: refetchAccount } = useQuery(
    LandingGetSalesforceAccountDocument,
    {
      skip: true,
    }
  )

  let salesforceAccount = null
  const initializePageData = useCallback(async () => {
    let accountDataResult = null
    try {
      const { data: refetchAccountDataResult } = await refetchAccount({
        input: {
          accountId,
        },
      })
      accountDataResult = refetchAccountDataResult
    } catch (e) {
      setError(true)
      return
    }

    if (shouldTrack() && accountId && !localStore?.getItem('accountId')) {
      identify({
        userId: accountId as string,
      })

      localStore?.setItem('accountId', accountId)
    }

    const updatedPageData: Partial<SalesforceOfferPageData> = {}

    // TODO: Set all (other) account data, from queries
    try {
      salesforceAccount = accountDataResult?.getSalesforceAccount
        ?.salesforceAccount as Core_SalesforceAccount
    } catch (e) {
      setError(true)
      return
    }

    updatedPageData.accountId = accountId

    // TODO: Only if they are "visible" and not isDeleted
    const salesforceScenarios = salesforceAccount?.activeOpportunity
      ?.salesforceScenarios as Core_SalesforceScenario[]
    updatedPageData.scenarios = salesforceScenarios

    // Lead information
    updatedPageData.fullName = salesforceAccount?.fullName || ''
    updatedPageData.firstName = salesforceAccount?.fullName?.split(' ')[0] || ''

    // Operator information
    updatedPageData.operator = {
      phoneNumber:
        salesforceAccount?.owner?.phoneNumber || DEFAULT_SALES_PHONE_NUMBER,
      email: salesforceAccount?.owner?.email || DEFAULT_SALES_EMAIL,
      calendlyUrl:
        salesforceAccount?.owner?.calendlyLink || DEFAULT_SALES_CALENDLY,
    }

    // Opportunity information

    // TODO: Set expiration date
    const currentDate = new Date()
    const fourtyDaysAgo = new Date()
    fourtyDaysAgo.setDate(currentDate.getDate() - 40)
    updatedPageData.expirationDate = fourtyDaysAgo
    updatedPageData.expirationDate = currentDate

    // Scenario information
    updatedPageData.selectedScenario = selectedScenario
    updatedPageData.setSelectedScenario = setSelectedScenario

    setPageData(updatedPageData)
    setLoading(false)
  }, [accountId, selectedScenario])

  const { offerPageContext } = pageData

  useEffect(() => {
    if (!invalidUuid) {
      initializePageData()
    }
  }, [initializePageData, invalidUuid, isMobile, isTablet])

  if (invalidUuid || error) {
    return (
      <ThemeProvider theme={flockTheme}>
        <LibraryThemeProvider>
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            id="calendlyPopupRoot"
            sx={{ backgroundColor: 'trustBlue.main', overflow: 'hidden' }}
          >
            <ErrorCard text="Please try refreshing the page or schedule a call with us to go over your estimated valuation." />
          </Box>
        </LibraryThemeProvider>
      </ThemeProvider>
    )
  }

  return (
    <>
      <OfferPageWrapper accountId={accountId}>
        {loading ? (
          <Box
            height="auto"
            pt="50px"
            sx={{ backgroundColor: 'trustBlue.main' }}
          >
            <Box
              width="100%"
              height="100vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          </Box>
        ) : (
          <OfferPageContextProvider
            value={offerPageContext as OfferPageContextType}
          >
            <Box
              height="auto"
              id="calendlyPopupRoot"
              sx={{ backgroundColor: 'trustBlue.main' }}
            >
              <OfferPage pageData={pageData as SalesforceOfferPageData} />
            </Box>
          </OfferPageContextProvider>
        )}

        <SectionLayout name="footer" backgroundColor="gray1.main">
          <Grid item xs={12}>
            <TrackedFooter />
          </Grid>
        </SectionLayout>
      </OfferPageWrapper>
    </>
  )
}

export default OfferPageV2
